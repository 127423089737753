.App {
  text-align: center;
  background-color: #f2f3ee;
  font-family: 'helveticaNow';
  font-size: '2vw';
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Branding-Bold';
}

.landingHighlightedWords {
  color: #7960a8;
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}

.ProblemsEnquanta {
  background-color: #264576;
  color: white;
}

.boldHeader {
  font-family: 'branding';
  font-weight: bold;
  font-size: 3vw;
}

.regularText{
  font-family: 'helveticaNow';
  font-size: 1.5vw;
}