@media (min-width:0px) {
    .postLandingImage {
        width: 140px;
    }
}

@media (min-width:600px) {
    .postLandingImage {
        width: 280px;
    }
}

@media (min-width:900px) {
    .postLandingImage {
        width: 280px;
    }
}

@media (min-width:1200px) {
    .postLandingImage {
        width: 280px;
    }
}

.EnQuantaLogoFirstSyllable {
    font-weight: bold;
    color: #7F5CAD;
}

.EnQuantaLogoRestOfTheWord {
    font-weight: bold;
    color: #054A91;
}