.problemsEnquantaList {
  list-style: none;
  padding: 3% 10% 0 0;
}

#problemsEnquantaListLeft {
  grid-area: 2 / 1 / 5 / 2;
}

#problemsEnquantaListRight {
  grid-area: 2 / 2 / 5 / 3;
}

.problemsEnquantaHeader {
  grid-area: 1 / 1 / 2 / 3;
  font-family: 'branding';
  font-weight: bold;
  font-size: 3vw;
  display: flex;
  justify-content: center;
}

.problemsEnquantaImagesContainer {
  grid-area: 2 / 1 / 6 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
}

.problemsEnquantaImageAligner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1vh;
}

li.regularText:not(:last-child) {
  margin-bottom: 15px;
}

.problemsEnquantaImageAligner>p {
  font-weight: bold;
  font-size: 1.3vw;
}

.problemsEnquantaImage {
  height: 100px;
}



.problemsEnquantaListHeader {
  font-size: 2.1vw;
  font-weight: bold;
  font-family: 'branding';
}